import React from "react"
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'


import './styles.scss'
//Components
// markup
const UnsubscribeConfirmedPage = () => {
  return (
    <Layout addedClass="page--registration-confirmed">
      <DocumentHeadMeta title='Registration Confirmation | ALL IN&reg; for Lupus Nephritis' description='' robots="noindex" canonicalURL='https://www.allinforlupusnephritis.com/registration-confirmation' />
      <section className="bg-white">
        <div className="container container--narrow subscribe-confirmation-container">
          <h1>Thank you</h1>
          <p>Thank you for registering. We look forward to connecting with you soon!</p>
        </div>
      </section>

    </Layout>
  )
}

export default UnsubscribeConfirmedPage

